import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../../components/Button';
import AliceCarousel from 'react-alice-carousel';
import LangProvider from '../../lang/lang-provider';
const _ = LangProvider.getConstants();

const Main = () => (
  <div className="container mx-auto px-8 lg:flex">
    <div className="text-center lg:text-left lg:w-1/2 md:pt-16">
      <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-secondary-blueDarker">
        <p className="text-primary">{_.main.title_section1}</p> {_.main.title_section_2}
      </h1>
      <p className="text-xl lg:text-2xl mt-6 font-light">
        {_.main.subtitle}
      </p>
      <div className="mt-8 md:mt-12">
        <AnchorLink href="#contact">
          <Button size="xl">{_.button.contact}</Button>
        </AnchorLink>
      </div>
    </div>
    <div className="lg:w-1/2">
      <AliceCarousel
        autoPlay
        autoPlayInterval={3000}
        duration={1500}
        buttonsDisabled
        dotsDisabled
        fadeOutAnimation
        transitionTimingFunction
      >
        <img
          alt="promotion"
          src={require('../../assets/banner2.png')}
          className="md:ml-4"
          width="600px"
          height="600px"
        />
        <img
          alt="promotion"
          src={require('../../assets/banner1.png')}
          className="md:ml-4"
          width="600px"
          height="600px"
        />
        <img
          alt="promotion"
          src={require('../../assets/banner3.png')}
          className="md:ml-4"
          width="600px"
          height="600px"
        />
        <img
          alt="promotion"
          src={require('../../assets/banner4.png')}
          className="md:ml-4"
          width="600px"
          height="600px"
        />
      </AliceCarousel>
    </div>
  </div>
);

export default Main;
